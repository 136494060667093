import * as React from 'react'

const PageBanner = ({ pageTitle, children }) => {
  return (
    <div className="py-8">
      <div className="container text-center">
        <h1 className="font-medium border-b">{pageTitle}</h1>
      </div>
    </div>
  )
}

export default PageBanner