import * as React from 'react'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import Header from './header'
import Footer from './footer'
import PageBanner from './page-banner'

const Layout = ({ pageTitle, headerOverlay, showPageBanner, children }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  return (
    <div className="flex flex-col min-h-screen">
      <Helmet>
        <html lang="en" />
        <title>{pageTitle} | {data.site.siteMetadata.title}</title>
        <meta name="description" content={data.site.siteMetadata.description} />
      </Helmet>
      <Header overlay={headerOverlay} />
      <main className="grow bg-white">
        { showPageBanner ? <PageBanner pageTitle={pageTitle} /> : "" }
        {children}
      </main>
      <Footer />
    </div>
  )
}

Layout.defaultProps = {
  showPageBanner: true
}

export default Layout