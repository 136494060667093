import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

const Footer = () => {
  const bbbEmbed = `<iframe border="0" frameborder="0" style="border: 0; height:57px; width:150px;" src="https://seal-central-westernma.bbb.org/frame/rbhzbam/bbb-336239.png?chk=E4EA01E1BC"></iframe>`
  return (
    <footer className="relative bg-mint text-black py-12">
      <nav>
        <div className="container text-center">
          <Link to="/" className={`h-24 w-60 inline-block mb-6`}>
            <StaticImage
              src="../images/logos/logo-sandainnovations-black.png"
              alt="S&amp;A Innovations logo"
              placeholder="none"
              className="object-contain"
            />
          </Link>
          <div className="mb-4">
            <ul className="flex flex-wrap justify-center -m-2">
              <li className="whitespace-nowrap p-2">
                <strong>Phone:</strong> <a className="hover:text-green focus:text-green" href="tel:508-667-3686">(508) 667-3686</a>
              </li>
              <li className="whitespace-nowrap p-2">
                <strong>Email:</strong> <a className="hover:text-green focus:text-green" href="mailto:sainnovations@hotmail.com">sainnovations@hotmail.com</a>
              </li>
            </ul>
          </div>
          <ul className="flex items-center justify-center mb-4 -mx-3">
            <li className="px-3 py-2" dangerouslySetInnerHTML={{__html: bbbEmbed}}>
            </li>
            <li className="px-3 py-2">
              <Link to="https://www.instagram.com/s_and_a_innovations/" target="_blank" aria-label="Instagram">
                <svg className="h-12 w-12 fill-black hover:fill-green focus:fill-green" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm0 7.082c1.602 0 1.792.006 2.425.035 1.627.074 2.385.845 2.46 2.459.028.633.034.822.034 2.424s-.006 1.792-.034 2.424c-.075 1.613-.832 2.386-2.46 2.46-.633.028-.822.035-2.425.035-1.602 0-1.792-.006-2.424-.035-1.63-.075-2.385-.849-2.46-2.46-.028-.632-.035-.822-.035-2.424s.007-1.792.035-2.424c.074-1.615.832-2.386 2.46-2.46.632-.029.822-.034 2.424-.034zm0-1.082c-1.63 0-1.833.007-2.474.037-2.18.1-3.39 1.309-3.49 3.489-.029.641-.036.845-.036 2.474 0 1.63.007 1.834.036 2.474.1 2.179 1.31 3.39 3.49 3.49.641.029.844.036 2.474.036 1.63 0 1.834-.007 2.475-.036 2.176-.1 3.391-1.309 3.489-3.49.029-.64.036-.844.036-2.474 0-1.629-.007-1.833-.036-2.474-.098-2.177-1.309-3.39-3.489-3.489-.641-.03-.845-.037-2.475-.037zm0 2.919c-1.701 0-3.081 1.379-3.081 3.081s1.38 3.081 3.081 3.081 3.081-1.379 3.081-3.081c0-1.701-1.38-3.081-3.081-3.081zm0 5.081c-1.105 0-2-.895-2-2 0-1.104.895-2 2-2 1.104 0 2.001.895 2.001 2s-.897 2-2.001 2zm3.202-5.922c-.397 0-.72.322-.72.72 0 .397.322.72.72.72.398 0 .721-.322.721-.72 0-.398-.322-.72-.721-.72z"/></svg>
              </Link>
            </li>
          </ul>
          
          <div className="inline-block text-left text-sm">
            <ul className="mb-4">
              <li>Fully Licensed and Insured</li>
              <li>MA CS-115650</li>
              <li>MA HIC-208092</li>
              <li>MA Lead Safe Renovation - LR004458</li>
              <li>OSHA 30 - 20602086535</li>
            </ul>
            <p>
              <strong>© Copyright 2023 S&amp;A Innovations</strong>
            </p>
          </div>
        </div>
      </nav>
    </footer>
  )
}

export default Footer