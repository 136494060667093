import React, { useState, useEffect } from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

function Header(props) {
  const [expand, setExpand] = useState(false)
  const [scroll, setScroll] = useState(false)
  const toggleExpand = () => {
    setExpand(!expand)
  }
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setScroll(window.pageYOffset > 0)
      window.addEventListener('scroll', () =>
        setScroll(window.pageYOffset > 0)
      );
    }
  })

  return (
    <header className={`${props.overlay ? 'fixed' : 'sticky'} top-0 text-white z-20 w-full`}>
      <div className={`absolute top-0 h-full w-full bg-green transition-opacity ease-in-out ${scroll || expand || !props.overlay ? `opacity-100` : `opacity-75`}`}>
      </div>
      <nav className="relative">
        <div className="container flex justify-between py-4">
          <Link to="/" className={`inline-block h-16 w-40`}>
            <StaticImage
              src="../images/logos/logo-sandainnovations-white.png"
              alt="S&amp;A Innovations logo"
              placeholder="none"
              className="object-contain"
            />
          </Link>
          <ul className="flex items-center font-serif text-2xl xl:text-3xl -mx-3 hidden md:flex">
            <li className="p-3 lg:px-10">
              <Link to="/services" className="text-white hover:text-mint focus:text-mint">Services</Link>
            </li>
            <li className="p-3 lg:px-10">
              <Link to="/gallery" className="text-white hover:text-mint focus:text-mint">Gallery</Link>
            </li>
            <li className="p-3 lg:px-10">
              <Link to="/about" className="text-white hover:text-mint focus:text-mint">About</Link>
            </li>
            <li className="p-3 lg:px-10">
              <Link to="/contact" className="text-white hover:text-mint focus:text-mint">Contact</Link>
            </li>
          </ul>
          <button onClick={toggleExpand} className="ml-3 text-white focus:outline-none md:hidden">
            <svg className="h-8 w-8 fill-white" viewBox="0 0 24 24">
              {expand ? (
                <path d="M23.954 21.03l-9.184-9.095 9.092-9.174-2.832-2.807-9.09 9.179-9.176-9.088-2.81 2.81 9.186 9.105-9.095 9.184 2.81 2.81 9.112-9.192 9.18 9.1z" />
              ) : (
                <path d="M24 6h-24v-4h24v4zm0 4h-24v4h24v-4zm0 8h-24v4h24v-4z" />
              )}
            </svg>
          </button>
        </div>
      </nav>
      <div className={`relative ${expand ? `md:hidden` : `hidden`}`}>
        <ul className="flex flex-col items-center font-serif text-2xl -mx-3 py-5">
          <li className="p-3 lg:px-10">
            <Link to="/services" className="text-white hover:text-mint focus:text-mint">Services</Link>
          </li>
          <li className="p-3 lg:px-10">
            <Link to="/gallery" className="text-white hover:text-mint focus:text-mint">Gallery</Link>
          </li>
          <li className="p-3 lg:px-10">
            <Link to="/about" className="text-white hover:text-mint focus:text-mint">About</Link>
          </li>
          <li className="p-3 lg:px-10">
            <Link to="/contact" className="text-white hover:text-mint focus:text-mint">Contact</Link>
          </li>
        </ul>
      </div>
    </header>
  )
}

Header.defaultProps = {
  overlay: false
}

export default Header